

<div class="row">
  <div class="col-md-12 col-sm-12 col-lg-12" *ngIf="displayQuestiontable">
  <br/>
  <div class="row">
    <div class="form-group col-md-10"></div>

  <div class="form-group col-md-2">
    <button type="submit" (click)="displayPage()" class="btn btn-primary btn-block col-md-12"><i class="fa fa-fw fa-plus"></i>&nbsp;Add</button>
    </div>
</div>
<div class="row">


<div class="form-group col-md-4">
  <input type="text" class="form-control col-md-12" style="margin-top: 6px;" id="search" [(ngModel)]="question" (input)="Search()" placeholder="Search here" name="search">
  </div>
  <div class="form-group col-md-8">
    <div class="d-flex justify-content-between p-2">
      <ngb-pagination [collectionSize]="collectionSize" style="margin-left: 40px;" [(page)]="page" [pageSize]="pageSize" (pageChange)="refreshCountries()">
      </ngb-pagination>

      <select class="custom-select" style="width: auto;" [(ngModel)]="pageSize" (ngModelChange)="refreshCountries()">
        <option [ngValue]="15">15 Records</option>
        <option [ngValue]="30">30 Records</option>
        <option [ngValue]="45">45 Records</option>
        <option [ngValue]="60">60 Records</option>
        <option [ngValue]="75">75 Records</option>
        <option [ngValue]="100">100 Records</option>
      </select>
    </div>
  </div>

</div>
  <div class="row" style="margin-top: -30px;">
    <div class="col-md-12">
        <table class="table table-hover table-bordered">
            <thead>
              <tr style="background-color: #fa5f05">
                <th style="color: white;cursor:pointer;font-weight: 100;font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;">S.No</th>
                <th style="color: white;cursor:pointer;text-align: center;font-weight: 100;font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;width: 500px" scope="col">Question</th>
                <!-- <th style="color: red;cursor:pointer" scope="col">Question Type</th> -->
                <th style="color: white;cursor:pointer;text-align: center;font-weight: 100;font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;width: 200px;" scope="col">
                 Complexity
                  <select id="ddlFliterComplexity" class="form-control" (change)="getFilterQuestions()" name="method" >
                    <option value="">All</option>
                    <option value="low">Low</option>
                    <option value="medium">Medium</option>
                    <option value="high">high</option>
                    </select>
                  </th>
                <th style="color: white;cursor:pointer;text-align: center;font-weight: 100;font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;width: 200px;" scope="col">Technology
                  <select id="ddlFlitertechnology" class="form-control" (change)="getFilterQuestions()" name="method">
                    <option value="">All</option>
                    <option value="1">.Net Developer</option>
                    <option value="2">Angular Developer</option>
                    <option value="12">Developer Intern</option>
                    <option value="13">QA Intern</option>
                    <option value="14">AI/ML Intern</option>
                    <option value="15">Data intern</option>
                    </select>
                  </th>
                    <th style="color: white;cursor:pointer;text-align: center;font-weight: 100;font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;width: 200px;" scope="col">Question Type
                      <select id="ddlFlitertype" class="form-control" name="method" (change)="getFilterQuestions()">
                        <option value="">All</option>
                        <option value="1">MultipleChoice</option>
                        <option value="3">Correct the Program</option>
                        </select></th>
                <th style="color: white;cursor:pointer;text-align: center;font-weight: 100;font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;width: 200px;" scope="col">Action</th>
                <th style="color: white;cursor:pointer;text-align: center;font-weight: 100;font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;width: 200px;" scope="col">Status</th>
              </tr>
            </thead>
            <tbody>
                <tr style='cursor:pointer' *ngFor="let row of usersQuestionsDataPagination2;let ndx=index">
                  <td>{{ndx+1}}</td>
                    <td>{{ row.question}}</td>

                    <!-- <td style="width: 20%">{{ row.questionType}}</td> -->
                    <td>{{ row.complexity}}</td>
                    <td *ngIf="row.technology==1">.Net Developer</td>
                    <td *ngIf="row.technology==2">Angular Developer</td>
                    <td *ngIf="row.questionType==1">MultipleChoice</td>
                    <td *ngIf="row.questionType==3">Correct the program</td>

                    <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<button  type="button" class="btn btn-success" (click)="editQuestions(row)" id="{{row.questionId}}"><i class="fa fa-pencil-square-o"></i></button></td>

                    <td *ngIf="row.status=='Review'">
                      <button
                  class="btn-success form-control col-md3" id="{{row.questionId}}" (click)="ApproveQuestion(row)"><i
                    class="fa fa-fw fa-paper-plane"></i>&nbsp;Approve</button>
                    </td>
                    <td *ngIf="row.status=='Approved'" style="color:blue;text-align: center;margin-top: -200px;">
                      Approved
                    </td>
                    <!-- <button  type="button" class="btn btn-danger"  id="{{row.questionId}}" (click)="DeleteQuestion(row)">Delete</button> -->
                    </tr>
            </tbody>
          </table>
          <div class="loading-indicator">
            <!-- <mat-progress-spinner *ngIf="loadspinner" mode="determinate" value='66' diameter="45"></mat-progress-spinner> -->
            <mat-spinner *ngIf="loadspinner" diameter="45"></mat-spinner>
          </div>
          <div class="d-flex justify-content-between p-2">
            <ngb-pagination [collectionSize]="collectionSize" [(page)]="page" [pageSize]="pageSize" (pageChange)="refreshCountries()">
            </ngb-pagination>

            <select class="custom-select" style="width: auto" [(ngModel)]="pageSize" (ngModelChange)="refreshCountries()">
              <option [ngValue]="15">15 Records</option>
              <option [ngValue]="30">30 Records</option>
              <option [ngValue]="45">45 Records</option>
              <option [ngValue]="60">60 Records</option>
              <option [ngValue]="75">75 Records</option>
              <option [ngValue]="100">100 Records</option>
            </select>
          </div>
    </div>
  </div>
  </div>
    </div>

    <app-post-question *ngIf="showPostQuestion"></app-post-question>
    <!-- <app-edit-question *ngIf="isDeveloper"></app-edit-question> -->

