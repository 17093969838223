<br/>
<h4 style="text-align: center;">Post Question</h4>
<div class="col-md-10 d-flex justify-content-center">
    <div class="container">
      <form class="form-horizontal" autocomplete="off" role="form" [formGroup]="registerForm" (ngSubmit)="postQuestion()">
          <div class="form-group">
              <label for="Questions Name" class="col-sm-12 control-label">Questions Name</label>
              <div class="col-sm-12">
                  <input type="text" id="txtquestionName" formControlName="questionName" placeholder="Questions Name" [ngClass]="{ 'is-invalid': submitted && f.questionName.errors }" class="form-control" autofocus>
                  <div *ngIf="submitted && f.questionName.errors" class="invalid-feedback">
                    <div *ngIf="f.questionName.errors.required">Question Name is required</div>
                </div>
              </div>
          </div>
          <div class="form-group">
            <label for="technology" class="col-sm-12 control-label">Technology</label>
            <div class="col-sm-12">
              <select id="ddltechnology" class="form-control" name="method">
                <option value="1">.Net Developer</option>
                <option value="2">Angular Developer</option>
                <option value="12">Developer Intern</option>
                <option value="13">QA Intern</option>
                <option value="14">AI/ML Intern</option>
                <option value="15">Data intern</option>
                </select>
            </div>
        </div>
          <div class="form-group">
              <label for="Complexity" class="col-sm-12 control-label">Complexity</label>
              <div class="col-sm-12">
                <select id="ddlComplexity" class="form-control" formControlName="complexity" name="method" [ngClass]="{ 'is-invalid': submitted && f.complexity.errors }">
                  <option value="">--Select--</option>
                  <option value="low">Low</option>
                  <option value="medium">Medium</option>
                  <option value="high">high</option>
                  </select>
                  <div *ngIf="submitted && f.complexity.errors" class="invalid-feedback">
                    <div *ngIf="f.complexity.errors.required">Complexity is required</div>
                </div>
              </div>
          </div>
          <div class="form-group">
              <label for="Question Type" class="col-sm-12 control-label">Question Type</label>
              <div class="col-sm-12">
                <select id="ddltype" class="form-control" name="method" formControlName="type" [ngClass]="{ 'is-invalid': submitted && f.type.errors }" (change)="selectQuetionsType()">
                    <option value="">--Select--</option>
                    <option value="1">MultipleChoice</option>
                    <!-- <option value="2">Program</option> -->
                    <option value="3">Correct the Program</option>
                    </select>
                    <div *ngIf="submitted && f.type.errors" class="invalid-feedback">
                      <div *ngIf="f.type.errors.required">Question Type is required</div>
                  </div>
              </div>
          </div>

          <div class="row" *ngIf="programedittext">
            <div class="col-md-12">
              <textarea type="text" id="txtquestionProgram" style="background-color: aliceblue;" rows="15" cols="100" placeholder="Program type here" class="form-control" autofocus></textarea>
              </div>
            </div>

          <div class="row" *ngIf="displaychoiceData">
            <div class="col-md-12">
          <table class="table table-striped table-bordered col-md-12">
            <thead>
              <tr>
                <th>Wants to Add Choices?
                  <a (click)="onEditCloseItems()" class="text-info float-right">
                    <i class="mdi mdi-{{isEditItems ? 'close' : 'plus'}} mdi-18px"></i>
                  </a>
                </th>
              </tr>
            </thead>
            <tbody *ngIf="!isEditItems">
              <tr *ngFor="let field of fieldArray; let i = index">
                <td *ngIf="field?.name">
                  <i (click)="deleteFieldValue(i)" class="mdi mdi-close mdi-18px"></i> {{field.name}}
                </td>
              </tr>
            </tbody>

            <tbody *ngIf="isEditItems">
              <tr *ngFor="let field of fieldArray; let ind = index">
                <td>
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <div (click)="deleteFieldValue(ind)" class="input-group-text">
                        <i class="mdi mdi-close mdi-18px"></i>
                      </div>
                    </div>
                    <input  class="form-control" id="field{{ind+1}}" autocomplete="off" type="text" name="{{field.name}}" placeholder="Choice...">
                    &nbsp;&nbsp;&nbsp;
                    <select id="ddldatatypesChoice" class="form-control" id="colval{{ind+1}}"  name="{{field.name}}">
                      <option [selected] value="false">false</option>
                      <option value="true">true</option>
                    </select>
                  </div>
                </td>
              </tr>
              <tr>
                <td align="right">
                  <button *ngIf="fieldArray.length <= 10" class="btn btn-secondary btn-lg1" type="button" (click)="addFieldValue(fieldArray.length)" style="margin-right:10px">Add More Choice</button>
                </td>
              </tr>
            </tbody>
          </table>
          </div>
          </div>
          <div class="form-group" style="right: 0px;position: absolute;;">
            <div class="row">
              <div class="col-md-4" style="margin-left: -120px;">
                  <button type="submit" mat-raised-button color="primary" [class.spinner]="loading" [disabled]="loading">
                    Submit
                  </button>
              </div>
              <div class="col-md-4" style="margin-left: -100px;">
                <button  routerLink="/questions" class="btn btn-info"  class="btn btn-success">Back</button>
            </div>
              </div>
          </div>
          <br/>
          <br/> <br/>
          <br/>
      </form>
  </div>
  </div>
